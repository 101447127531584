<template>
  <div class="bgapp d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="4">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <CForm>
                <h1>Registration</h1>
                <p class="text-muted">
                  Create your account before access our features.
                </p>
                <!-- <CInput placeholder="Name" v-model="nama">
                  <template #prepend-content
                    ><CIcon name="cil-user"
                  /></template>
                </CInput> -->
                <CInput
                  placeholder="Email"
                  autocomplete="email"
                  v-model="email"
                  prepend="@"
                />
                <!-- <CInput
                  placeholder="Username"
                  autocomplete="username"
                  v-model="username"
                >
                  <template #prepend-content
                    ><CIcon name="cil-user"
                  /></template>
                </CInput> -->
                <CInput
                  placeholder="Password"
                  type="password"
                  v-model="password"
                  autocomplete="new-password"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>
                <CInput
                  placeholder="Repeat password"
                  type="password"
                  v-model="repassword"
                  autocomplete="new-password"
                  class="mb-4"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>
                <div class="form-group">
                  <!-- <slide-unlock
                      ref="vueslideunlock"
                      :auto-width="true"
                      :circle="true"
                      :disabled="false"
                      :noanimate="false"
                      :width="400"
                      :height="60"
                      text="slide to Capctha"
                      success-text="success"
                      @completed="onVerify()"
                  /> -->
                <vue-recaptcha ref="recaptcha" @verify="onVerify" @error="this.myCap = true" sitekey="6LfuC5UeAAAAABP6NoYPM3WcGo0f_Qo_4hhvqwM6" />
                </div>
                <CRow>
                    <CCol col="5" class="text-left">
                     <CButton color="link" to="/login" class="px-0"
                        >Just Login!</CButton>
                    </CCol>
                    <CCol col="7" class="text-right">
                      
                      <CButton color="success" v-on:click="loginNow" block
                        >Register</CButton>
                    </CCol>
                  </CRow>
              </CForm>
            </CCardBody>
            <!-- <CCardFooter class="p-4">
              <CRow>
                <CCol col="6">
                  <CButton block color="facebook"> Facebook </CButton>
                </CCol>
                <CCol col="6">
                  <CButton block color="twitter"> Twitter </CButton>
                </CCol>
              </CRow>
            </CCardFooter> -->
          </CCard>
        </CCol>
      </CRow>
    </CContainer>

    <CModal title="Warning" :show.sync="myCap" size="sm">
      Failed! Wrong Capctha
    </CModal>
    <CModal title="Warning" :show.sync="myModal2" size="sm">
      Failed! Registration faild, please check your internet connection...
    </CModal>
    <CModal title="Warning" :show.sync="myModal" size="sm">
      Failed! Please fill your registration acount...
    </CModal>
    <CModal title="Sukses" color="success" :show.sync="mySukses" size="sm">
      Success! Account has been registered. Please verify your email
      <template #footer>
        <CButton @click="goLogin()" color="dark">Close</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { VueRecaptcha } from 'vue-recaptcha';
// import SlideUnlock from "vue-slide-unlock";
import axios from "axios";
export default {
  name: "Register",
  components: { VueRecaptcha },
  data() {
    return {
      tabs: [],
      myModal: false,
      myModal2: false,
      mySukses: false,
      myCap:false,
      users: "",
      dismissCountDown: 0,
      country: "",
      nama: "",
      password: "",
      repassword: "",
      email: "",
      telpon: "",
      rcvr: "",
      activeTab: 1,
      robot:false,
      affiliator : "950307",
    };
  },
  mounted(){
    this.cekRef();     
  },
  methods: {
    // validator (val) {
    //   return val ? val.length >= 4 : false
    // },
    cekRef: function (){
          let aff = this.$route.params.refer;
          if(aff){
            let aff2 = aff.split("=");
            this.affiliator = aff2['1'];
            // alert(this.affiliator);  
          }
    },
    onVerify: function (response) {
      if (response) this.robot = true;
    },
    goLogin: function(){
      this.mySukses = false;
      this.$router.push("/login/");
    },  
    loginNow: function (event) {
      // Simple POST request with a JSON body using axios
      const user = {
        nama: "",
        email: this.email,
        password: this.password,
        referal : this.affiliator,
      };
      // alert(localStorage.getItem("jwt"));
      if(this.robot==true){
        if (
          (this.password == null || this.password == "",
          this.email == null || this.email == "")
        ) {
          this.myModal = true;
        } 
        else {
          if (this.password == this.repassword) {
            axios
              .post(
                process.env.VUE_APP_BASE_URL+"registerapp",
                user
              )
              .then((response) => {
                // console.log(response);
                // alert(response.data);
                // this.tabs = response.data;/
                if (response.data == "sukses") {
                  
                  this.mySukses = true;

                  // this.myModal2 = true;
                } else {
                  // alert(JSON.stringify(user));
                  this.myModal2 = true;
                }
                this.robot=false;
              });
          } else {
            this.myModal = true;
          }
        }
      }
      else{
        this.myCap=true;
      }
    },
  },
};
</script>
